.ccnavbarcontainer{
    /* padding:2px; */
    width:100%;
    margin:1px;
    text-align:center;
    min-height: 60px;
}

.ccnavbarlogo{
    float:left;
    padding:auto;
    margin:1px;
}
  
.ccnavbartitle{  
    padding:auto;
    font-size:28px;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    vertical-align: middle;
    line-height:60px;
}
.ccnavbartitle-address { 
    top:20px;
}

.ccitem {
    margin: 20px 0;
}
.cclist-item{
    border: 1px solid #cdcdcd !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
}
.ccimg-header{
    width:60px;    
}
.cclist-table-hdr{
    padding:10px;
}
.cclist-table-heading {
    text-align: left;
    font-size:20px;
    font-weight:500;
}
.ccdetail-label{
    width: 100px
}
.ccimg-icon {
    width:60px;
    padding:5px;
}
@media screen and (max-width: 576px) {
    .ccimg-icon {
        width:50px;
    }
    .ccimg-header{
        width:50px;    
    }
    .ccnavbartitle{
        display: contents;
    }
}
.cclist-header .accordion-button{
    padding:12px;
    background: #fafafa;
}
.cclist-header-block span{
    padding-top:5px;
}
.ccmodalbuttons .btn {
    margin:10px 5px;
}
.modal-header{
    padding:10px !important;
}
.modal-header .btn-close{
    font-size:12px !important;
    padding-right:20px !important;
}
.showelement{
    display: block !important;
}
.hideelement{
    display: none !important;
}
.companylogocontainer {
    padding-top: 0;
    padding-bottom: 0;
}
.companylogo{
    height: 60px;
}
.companylogoaddress{
    font-size:13px !important;
    font-weight: 500 !important;
    text-align: left;
    line-height:normal;
}
.aims-button-primary {
    color: #fff;
    background-color: #661F55;
    border-color: #661F55;
}
.aims-button-secondary {
    color: #fff;
    background-color: #E3E6E6;
    border-color: #E3E6E6;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.container{
    margin-bottom: 60px;
}
.aimsAlertHeader .modal-title{
    font-size: 14px;
}
.text-expirydate{
    padding-left: 10px;
}
.text-defaultard{
    padding-left: 20px;
}