@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinner-container {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #fff;
    z-index: 9999;
  }
  .loading-spinner {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    z-index: 10000;
  }
  .loader-text{
    font-weight: bold;
    position: absolute;
    top: 52%;
    left: 43%;
    z-index: 10000;
  }
  @media screen and (max-width: 576px) {
    .loader-text{
      left: 35%;
  }
}
  @media screen and (min-width: 576px) and (max-width: 960px) {
  .loader-text{
    left: 35%;
  }
}