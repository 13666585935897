.viewinvoiceDetails {
  float: right;
  padding: 10px;
  margin: 1px;
  background-color: white;
  width: 35%;
  border-radius: 10px;
}
.viewmerchantDetails {
  float: right;
  padding: 10px;
  margin: 1px;
  background-color: white;
  width: 35%;
  clear: right;
  margin-top: 10px;
  border-radius: 10px;
}
.invoicecclist-table-hdr {
  padding: 10px;
}
.invoicecclist-header {
  padding-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #0d6efd;
  text-align: left;
}
.invcclist-header .accordion-button {
  padding: 7px;
  background: #def4fbf5;
}
.invoicecclist-default {
  padding-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #0d6efd;
  /* text-align: right; */
  padding-left: 10px;
}
.viewccDetails {
  float: left;
  padding-left: 50px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin: 1px; */
  background-color: white;
  width: 60%;
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.viewReceipt {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 100px;
  margin-right: 100px;
  background-color: white;
}
.viewReceiptSuccess {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #ff3399;
}
.receipt-title {
  text-align: center;
  font-size:28px;
  font-weight: 500;
  width: 100%;
  left: 0;
  text-align: center;
  vertical-align: middle;
  line-height:60px;
}
.receipt-header {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.receipt-column1 {
  text-align: right;
  font-size: 12px;
  font-weight: 800;
  border: 2px solid grey;
  margin: -2px;
}
.receipt-column2 {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  margin: -2px;
}
.receipt-column {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
.receipt-addr2 {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}
.receipt-addr1 {
  text-align: left;
  vertical-align: top;
  font-size: 12px;
  font-weight: 500;
  height: 1.3;
}
.payinvoicenavbarcontainer {
  background-color: #efefef;
}
.payinvoicefooter {
  float: right;
  width: 35%;
  clear: right;
  font-size: 10px;
  font-weight: 700;
}
.payinvoiceheader {
  padding: auto;
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  vertical-align: middle;
}
.invoicecclist-table-heading {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  padding-top: 10px;
}
.invoice-table-heading {
  width: 100%;
}

.invoice-row {
  width: 100%;
  margin: 10px;
  text-align: left;
}
.invoice-row .invoice-column1 {
  text-align: left;
  color: #808080;
  padding: 3px;
}
.invoice-row .invoice-column1.invoice-column-hr {
  padding: 0;
}

.invoice-row hr {
  margin: 0 -10px;
  height: 3px;
  background: #808080;
}

.btn.btn-viewinvoice {
  margin-top: 5px;
  padding: 3px 10px 5px 10px;
}
.btn.btn-custom {
    --bs-btn-border-width: 2px;
    border-width: 2px;
}
/* .invoice-column1 {
    text-align: left;
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 0.4rem;
    font-weight: 400px;
    line-height: 1px;
} */
.invoice-column-light {
  text-align: right;
  color: #808080;
}
.invoice-column2 {
  text-align: right;
  word-break: break-word;
  width:50%;
}
.small-font {
    font-size: 0.75rem !important;
}

/* .hover-text {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
} */
.payment-text {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: gray;
}
.copyright-text {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: dimgray;
  line-height: 10px;
  margin: 10px 0;
  padding:10px;
}
.copyright-text .bi {
    font-size: 12px;
    padding-right:5px;
    -webkit-text-stroke: 1px;
    stroke-width: 1px;
}
.button-print {
  text-align: right;
  margin-right: 100px;
}
.button-text {
  text-align: center;
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #DA279D;
  border: 0px;
  line-height: 40px;
  border-radius: 5px;
}
.button-text-receipt {
  text-align: center;
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #DA279D;
  border: 0px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 2px 20px 2px 10px;
}
.btn-link{
  color: darkgray !important;
  padding: 0;
  
}
.text-box {
  text-align: left;
  line-height: 30px;
  width: 120px;
}
.button-image {
  background-color: white;
  border: 0px;
}
.hide-on-small {
  display: block;
}
.hide-on-large {
  display: none;
}
.ccinvimg-header {
  width: 40px;
}
.invoiceccimg-icon {
  width: 50px;
  padding: 5px;
}
.footer-payment {
  width: 60%;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  clear:left;
  padding-top: 5rem;
}
.invoice-download-bold { 
    padding: 1px 2px;
    -webkit-text-stroke: 1px;
    stroke-width: 1px;
    font-size: 20px; 
    position: relative;
}
.invoice-print-bold { 
  padding: 1px 2px;
  -webkit-text-stroke: 1px;
  stroke-width: 1px;
  font-size: 20px; 
  position: relative;
}
.invoice-download-bold:hover{
    color: #808080;
}
.invoice-print-bold:hover{
  color: #808080;
}
.invoice-download-bold:hover::after {
  content: "Download";
  font-size: 10px; 
  position: absolute;
  top: -10px; 
  left: 0; 
}
.invoice-print-bold:hover::after {
  content: "Print";
  font-size: 10px; 
  position: absolute;
  top: -10px; 
  left: 0; 
}
.invoice-white{
    fill:#fff;
}
.invoice-button{
  background-color:  #d3d3d345;
  float: left;
  width: 100%;
  clear: left;
}
.companylogoreceipt{
  height: 60px;
}
.RedirectPage {
  margin-right: 15px !important;
  vertical-align: bottom !important;
    color: blue !important;
    padding: 0 !important;
    text-decoration: underline !important;
}
@media print {
  .button-print {
    display: none !important;
  }
  .receipt-header {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .receipt-column1 {
    text-align: right;
    font-size: 10px;
    font-weight: 700;
    border: 2px solid grey;
    margin: -2px;
  }
  .receipt-column2 {
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    margin: -2px;
  }
  .receipt-column {
    text-align: left;
    font-size: 10px;
    font-weight: 400;
  }
  .receipt-addr2 {
    text-align: left;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
  }
  .receipt-addr1 {
    text-align: left;
    vertical-align: top;
    font-size: 10px;
    font-weight: 400;
    height: auto;
  }
  .viewReceipt {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 50px;
    margin-right: 50px;
    background-color: white;
  }
}
@media only screen and (max-width: 576px) {
  .hide-on-large {
    display: block;
  }
  .button-print {
    text-align: right;
    margin-right: 50px;
  }
  .receipt-title {
    text-align: center;
    font-size:28px;
    font-weight: 400;
    width: 100%;
    left: 0;
    text-align: center;
    vertical-align: top;
    line-height:40px;
  }
  .invoiceccimg-icon {
    width: 40px;
  }
  .hide-on-small {
    display: none;
  }
  .viewccDetails {
    float: left;
    padding-left: 20px;
    margin-bottom: 10px;
    background-color: white;
    width: 100%;
    border-radius: 20px;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
  }
  .viewinvoiceDetails {
    float: left;
    margin: 0;
    background-color: white;
    width: 100%;
    border: solid 20px #d3d3d345;
    border-radius: 0;
  }
  .viewmerchantDetails {
    float: left;
    padding: 10px;
    margin: 0;
    background-color: white;
    width: 100%;
    clear: left;
    margin-top: 0;
    border-radius: 0;
    border: solid 20px #d3d3d345;
  }
  .footer-payment {
    width: 100%;
    background-color: white;
    /* border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; */
    border-radius: 0;
    clear:left;
    padding-top: 1rem;
  }
  
.receipt-header {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.receipt-column1 {
  text-align: right;
  font-size: 10px;
  font-weight: 700;
  border: 2px solid grey;
  margin: -2px;
}
.receipt-column2 {
  text-align: right;
  font-size: 10px;
  font-weight: 400;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  margin: -2px;
}
.receipt-column {
  text-align: left;
  font-size: 10px;
  font-weight: 400;
}
.receipt-addr2 {
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
}
.receipt-addr1 {
  text-align: left;
  vertical-align: top;
  font-size: 10px;
  font-weight: 400;
  height: auto;
}
.viewReceipt {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: white;
}
}
@media only screen and (min-width: 576.10px) and (max-width: 767.98px) {
  .invoicecclist-default {
    font-size: 12px;
    font-weight: 500;
    color: #0d6efd;
    /* text-align: right; */
    margin-left: 180px;
  }
}
.custom-accordion .accordion-item {
  border: none;
}
.custom-accordion .accordion-body {
  padding: 0;
  background-color: lightgray;
}
.custom-accordion .accordion-button {
  background-color: transparent;
  color: blue;
  border: none;
  text-align: center;
  display: inline-block;
}
.custom-accordion .accordion-button:focus {
  box-shadow: none;
}

.custom-accordion .accordion-button::after {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: 5px;
  transform: rotate(0deg);
}
.custom-accordion .accordion-button:not(.collapsed) {
  color: blue;
  background-color: transparent;
}
.custom-accordion .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}
@media only screen and (min-width: 1000px) {
  .invoicecclist-header {
    font-size: 16px;
  }
}
.payinvoice-button-icon{
  width:24px; 
  height: 24px;
  margin-right: 5px;
  margin-top: -5px;
}
.payinvoice-button-text{
  font-size:18px;
}
